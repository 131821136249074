html {
  background-color: white;
}

a {
  color: #0366d6;
}

.viewport-fill {
  height: 100vh;
  height: calc(var(--1dvh, 1vh) * 100);
  height: 100dvh;
}